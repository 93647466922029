import axios from 'axios';
import packageJson from '../../package.json';
import { getAuthToken } from 'src/utils/cookies';
import { profileLogout } from '../store/auth/actions';
import { checkPermission } from '../utils/helpers';
import { showErrorFactory } from '../utils/helpersError';

console.log('version:', packageJson?.version);

const locale = localStorage.locale ? localStorage.locale : 'en';
export const api = axios.create({
    baseURL: '',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept-Language': String(locale),
    },
});

api.interceptors.request.use(
    (config) => {
        const token = getAuthToken();
        if (token) {
            const validate = checkPermission(token);
            if (!validate) {
                profileLogout();
                return;
            }
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => error,
);

api.interceptors.response.use(
    (response) => response,
    (error) => {
        showErrorFactory(error);
        return { error };
    },
);
//
// loadConfig().then((config) => {
//     if (config?.REACT_APP_API_URL) {
//         api.defaults.baseURL = config?.REACT_APP_API_URL;
//     }
// });
