import React from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
// import UserCreateBalanceForm from 'src/components/UserCreateBalanceForm';
// import ChanceSpeed from 'src/pages/SpeedChance';
import Sidebar from 'src/components/Sidebar';
import UserCreateBalanceForm from 'src/components/UserCreateBalanceForm';
import NotPage from 'src/pages/404';
import Chance from 'src/pages/Chance';
import { getAuthToken } from 'src/utils/cookies';

import LoginModal from '../components/Modals/LoginModal';

const RequireAuth = ({ children }: { children: JSX.Element }) => {
    const auth = getAuthToken();
    const location = useLocation();

    if (!auth) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
};

const NotRequireAuth = ({ children }: { children: JSX.Element }) => {
    const auth = getAuthToken();

    if (auth) {
        return <Navigate to="/create-trans-by-admin" />;
    }

    return children;
};

const RenderRoutes: React.FC<any> = () => {
    const auth = getAuthToken();
    const location = useLocation();
    return (
        <>
            <Routes>
                <Route
                    path="/login"
                    element={
                        <NotRequireAuth>
                            <div className="login-page">
                                <LoginModal />
                            </div>
                        </NotRequireAuth>
                    }
                />
                <Route
                    path="/chance"
                    element={
                        <RequireAuth>
                            <>
                                <Sidebar />
                                <Chance />
                            </>
                        </RequireAuth>
                    }
                />
                <Route
                    path="/create-trans-by-admin"
                    element={
                        <RequireAuth>
                            <>
                                <Sidebar />
                                <UserCreateBalanceForm />
                            </>
                        </RequireAuth>
                    }
                />
                <Route path="/*" element={<NotPage />} />
            </Routes>
            {!auth && location.pathname !== '/login' && location.pathname === '/' && <Navigate to="/login" />}
            {auth && location.pathname === '/' && <Navigate to="/create-trans-by-admin" />}
        </>
    );
};

export default RenderRoutes;
