import { api } from 'src/api';

export const loadConfig = async () => {
    const apiUrlByMode =
        process.env.NODE_ENV === 'production'
            ? window.runtimeConfig.API_URL
            : process.env.REACT_APP_API_URL || window.runtimeConfig.API_URL;
    if (validateOrRejectConfig(apiUrlByMode)) {
        api.defaults.baseURL = apiUrlByMode;
    }
};

const validateOrRejectConfig = (url) => {
    try {
        const test = new URL(url);

        return true;
    } catch (e) {
        console.error(`Invalid URL: ${url}`);
        return false;
    }
};
