import { FIVE_MINUTES_MS, ONE_HOUR_MS, TWO_HOURS_MS, ONE_WEEK_MS } from "./dictionary/constants";

export class Config {
  // # app
  static DEFAULT_PASS_RESET_TIME_MS: number = FIVE_MINUTES_MS;
  static DEFAULT_ONLINE_RESET_TIME_MS: number = FIVE_MINUTES_MS;
  static ONE_HOUR_MS: number = ONE_HOUR_MS;
  static ONE_WEEK_MS: number = ONE_WEEK_MS;
  static DEFAULT_MIN_OUT_AMOUNT: number = 10000; // * 100, 100$
  static DEFAULT_MIN_BET_CHANCE_AMOUNT: number = 1000; // * 100, 10$
  static DEFAULT_LIMIT_MAX_ATTEMPTS: number = 3; // restore/signup by email/sms per hour
  static DEFAULT_LIMIT_MAX_CODE_ATTEMPTS: number = 5; // restore/signup by email/sms
  static DEFAULT_UPLOAD_FILETYPES: string[] = [".png", ".jpg", ".jpeg", ".ico", ".svg"];
  static DEFAULT_UPLOAD_MAX_COUNT: number = 3;
  static DEFAULT_UPLOAD_MAX_SIZE: number = 5000000; // 5mb in bytes
  static DEFAULT_UPLOAD_FILENAME_LENGTH_MAX: number = 50;
  static DEFAULT_PAGE_NUMBER: number = 1;
  static DEFAULT_AMOUNT_PER_PAGE: number = 50;
  static DEFAULT_AMOUNT_PER_PAGE_MAX: number = 500;
  static DEFAULT_USER_BALANCE: number = 0;
  static DEFAULT_USER_CASHBACK: number = 0;
  static DEFAULT_CHANCE_PERIOD_FOR_FIND: number = TWO_HOURS_MS;
  static LOCALES: string[] = ["en", "he", "ru"]; // first is default
  static BIRTH: number = 18; // min reg old of user
  static CURRENCY: string = "USD"; // min reg old of user
  static VALIDATE_LANG_LENGTH: number = 2;
  static VALIDATE_COUNTRY_LENGTH: number = 2; // ISO 3166-1 Alpha-2
  static VALIDATE_CURRENCY_LENGTH: number = 3;
  static VALIDATE_CODE_LENGTH: number = 8;
  static VALIDATE_STRING_LENGTH_MIN: number = 2;
  static VALIDATE_STRING_LENGTH_MAX: number = 100;
  static VALIDATE_SECURE_LENGTH_MIN: number = 8;
  static VALIDATE_SECURE_LENGTH_MAX: number = 32;
  static VALIDATE_LOGIN_LENGTH_MIN: number = 4;
  static VALIDATE_LOGIN_LENGTH_MAX: number = 32;
  static VALIDATE_EMAIL_LENGTH_MIN: number = 6;
  static VALIDATE_EMAIL_LENGTH_MAX: number = 254;
  static VALIDATE_PHONE_LENGTH_MIN: number = 10;
  static VALIDATE_PHONE_LENGTH_MAX: number = 18; // 15 is max by E.164 + code
  static VALIDATE_IP_LENGTH_MAX: number = 39; // max IPv length, IPv6 (32+7)
  static VALIDATE_TRC20_LENGTH: number = 34; // USDT-TRC20
  static VALIDATE_ERC20_LENGTH: number = 42; // USDT-ERC20
  static VALIDATE_SECURE_DB_LENGTH_MAX: number = 100;
  static VALIDATE_DB_STRING_LENGTH_MAX: number = 200;
  static VALIDATE_PAYLOAD_DB_LENGTH_MAX: number = 500;
}
