import { IntlReducer as Intl } from 'react-redux-multilingual';
import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';
import { removeAuthToken } from 'src/utils/cookies';

import authReducer from './auth/reducer';
import authSaga from './auth/sagas';
import { AuthState } from './auth/types';
import setAuthorizationHeader from './setAuthorizationHeader';

export interface ApplicationState {
    auth: AuthState;
}

export const initialState = () => ({});

const appReducer = combineReducers({
    auth: authReducer,
    Intl,
});

export const rootReducer = (state: any, action: any) => {
    if (action.type === '@@auth/PROFILE_LOGOUT') {
        removeAuthToken();
        setAuthorizationHeader(null);
        state = initialState();
    }
    return appReducer(state, action);
};

export function* rootSaga() {
    yield all([fork(authSaga)]);
}
