import React from 'react';

import LoginForm from '../LoginForm/Form';

import ModalHOC from './ModalHOC';

const LoginModal: React.FC<any> = (props) => (
    <LoginForm
        handlerStatus={props.handlerStatus}
        switchModal={true}
        loginRequest={props.loginRequest}
        translate={props.translate}
    />
);

export default ModalHOC(LoginModal);
