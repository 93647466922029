// MS
export const TEN_SECONDS_MS = 10000;
export const TWENTY_SECONDS_MS = 20000;
export const FIFTEEN_SECONDS_MS = 15000;
export const HALF_MINUTE_MS = 30000;
export const ONE_MINUTE_MS = 60000;
export const FIVE_MINUTES_MS = 300000;
export const TWENTY_MINUTES_MS = 1200000;
export const ONE_HOUR_MS: number = 60 * 60 * 1000; // 1h (3600000 ms)
export const TWO_HOURS_MS: number = 60 * 60 * 1000 * 2; // 1h (7200000 ms)
export const ONE_WEEK_MS: number = 604800000;
// SEC => 36000: 10h, 5400: 1.5h, 3600: 1h, 900: 15m
export const TEN_HOURS_SEC: number = 36000;
export const HOUR_AND_A_HALF_SEC: number = 5400;
export const ONE_HOUR_SEC: number = 3600;
export const FIFTEEN_MINUTES_SEC: number = 900;
