import React from 'react';
import { withTranslate } from 'react-redux-multilingual';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as Logo } from 'src/assets/images/not-page-logo.svg';
import './404.scss';

const NotPage = ({ translate }) => (
    <div className="NotPage">
        <Logo />
        <p>{translate('the_current')}</p>
        <a href="/">{translate('return_home')}</a>
    </div>
);

export default withTranslate(NotPage);
