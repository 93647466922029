import { removeAuthToken } from 'src/utils/cookies';

import setAuthorizationHeader from '../store/setAuthorizationHeader';

export const getYears = Array.from({ length: 51 }, (_, i) => {
    const year = new Date().getFullYear() - 18 - i;

    return {
        value: year,
        label: year,
    };
});

export const getMount = Array.from({ length: 12 }, (_, i) => {
    const mount = i + 1;
    return {
        value: mount,
        label: mount,
    };
});

export const getDays = Array.from({ length: 31 }, (_, i) => {
    const day = i + 1;
    return {
        value: day,
        label: day,
    };
});

export const profileLogouts = () => {
    removeAuthToken();
    setAuthorizationHeader(null);
};

export const translationOptions = [
    { label: 'EN', value: 'en', name: 'EN' },
    { label: 'RUS', value: 'ru', name: 'RU' },
    { label: 'HE', value: 'he', name: 'HE' },
];

export const monthsDays = {
    1: 31,
    2: 29,
    3: 31,
    4: 30,
    5: 31,
    6: 30,
    7: 31,
    8: 31,
    9: 30,
    10: 31,
    11: 30,
    12: 31,
};

export const debounce = (func, timeout = 300) => {
    let timer;
    return (...args) => {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            timer = null;
            func(...args);
        }, timeout);
    };
};
