import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-redux-multilingual';
import { BrowserRouter as Router } from 'react-router-dom';
import { loadConfig } from 'src/api/configUtils';

import App from './App';
import translations from './assets/translations';
import { profileRequest } from './store/auth/actions';
import configureAppStore from './store/configureStore';
import './index.css';
import { getAuthToken } from './utils/cookies';

const store = configureAppStore();

const token = getAuthToken();

declare global {
    interface JQuery {
        marquee: any;
    }
    interface Window {
        NotificationManager: any;
        webSockets: any;
        Swiper: any;
        marquee: any;
        md: {
            mobile: () => {};
        };
        resizeViewport: () => {};
    }
}

const render = () => {
    loadConfig().then(() => {
        if (token) {
            store.dispatch(profileRequest());
        }
        ReactDOM.createRoot(document.getElementById('root')).render(
            <React.StrictMode>
                <Provider store={store}>
                    <IntlProvider translations={translations} locale="ru">
                        <Router>
                            <App />
                        </Router>
                    </IntlProvider>
                </Provider>
            </React.StrictMode>,
        );
    });
};

render();
