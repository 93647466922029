import React from 'react';
import classNames from 'classnames';
import { ReactComponent as Check } from 'src/assets/images/check.svg';
import './checkBox.scss';

const CheckBox = ({ className, checked = false, onChange = () => '', label = '', hideLabel = false, classNameBox = '' }) => (
    <div className={classNames('CheckBox', className, { 'CheckBox-checked': checked })}>
        <div onClick={onChange} className={classNames('CheckBox-box', classNameBox)}>
            {checked && (
                <div className="CheckBox-checkmark">
                    <Check />
                </div>
            )}
        </div>
        {!hideLabel && label && <span className="CheckBox-label">{label}</span>}
    </div>
);

export default CheckBox;
