/* eslint-disable */
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { IntlActions } from 'react-redux-multilingual';
import Dropdown from 'src/components/NewUI/dropdown/Dropdown';
import { translationOptions } from 'src/utils/utils';
import { withTranslate } from 'react-redux-multilingual';
import './styles.scss';

interface ILocale {
    dispatch: any;
    translate: any;
}

const Locale: React.FC<ILocale> = ({ dispatch, translate }) => {
    const defaulLanguge = useMemo(() => {
        const lang = localStorage.getItem('locale');
        return translationOptions.find((el) => el.value === lang) || translationOptions[0];
    }, []);

    const selectLocale = (selectedOption) => {
        localStorage.locale = selectedOption;

        const body = document.querySelector('body') as HTMLElement;
        selectedOption === 'he' ? body.classList.add('rtl') : body.classList.remove('rtl');

        dispatch(IntlActions.setLocale(selectedOption));
    };

    return (
        <Dropdown
            className="dropdown-header"
            onSelect={({ value }) => {
                selectLocale(value);
            }}
            translate={translate}
            options={translationOptions}
            defaultSelected={defaulLanguge}
        />
    );
};

export default connect(null, (dispatch) => ({
    dispatch,
}))(withTranslate(Locale));
