import { useState, useEffect } from 'react';
import './dropdown.scss';
import arrowImg from 'src/assets/images/arrow.svg';
import { ReactComponent as Check } from 'src/assets/images/checkMark.svg';
import { ReactComponent as Lang } from 'src/assets/images/lang.svg';
import classNames from 'classnames';
import { useDetectClickOutside } from 'react-detect-click-outside';

function Dropdown({ options, onSelect, defaultSelected, className, translate }) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(defaultSelected);
    const ref = useDetectClickOutside({ onTriggered: () => setIsOpen(false) });

    useEffect(() => {
        setSelectedOption(defaultSelected);
    }, [defaultSelected]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionSelect = (option) => {
        if (option.value === selectedOption.value) return;
        setSelectedOption(option);
        setIsOpen(false);
        if (onSelect) {
            onSelect(option);
        }
    };

    return (
        <div className={classNames('dropdownCustom', className)} ref={ref}>
            <div className="dropdownCustom-toggle" onClick={toggleDropdown}>
                <Lang />
                <span>{selectedOption ? selectedOption.name : 'Select'}</span>
                <img src={arrowImg} alt="arrow" className={classNames('arrow', { ['arrow--toggled']: isOpen })} />
            </div>
            {isOpen && (
                <div className="dropdownCustom-options-wrapper">
                    <div className="dropdownCustom-options-wrapper-title">
                        <span>{translate('choose-language')}</span>
                    </div>
                    {options.map((option) => (
                        <div
                            className={classNames('dropdownCustom-option', {
                                ['dropdownCustom-option--selected']: option.value === selectedOption.value,
                            })}
                            key={option.value}
                            onClick={() => handleOptionSelect(option)}
                        >
                            {option.label}
                            {option.value === selectedOption.value && <Check />}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default Dropdown;
